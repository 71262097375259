// jQueryId: jQuery

/**********************************************************************
 * Name: pns_claim.js
 * Author: Enas Hasan
 * Date: June 23,2016
 *
 * Description: Javascript functions for PayAndSubmit claim form
 *********************************************************************/

$(document).ready(function(){

    if ($("#spinner-holder").length > 0 ){
        $("#spinner-holder").hide();
    }

    var $noneSelectedText;
    var $numSelected;
    var $language;
    var current_year = new Date().getFullYear();
    var maxFileSize = 7340032;
    $url = window.location.href;
    if($url.indexOf('/en/') >= 0) {
        $noneSelectedText = 'None selected';
        $numSelected = ' selected';
        $language = 'en';
    } else {
        $noneSelectedText = 'Aucune sélection';
        $numSelected = ' sélections';
        $language = 'fr';
    }
    $('.sti-date-picker').datepicker({
        language: $language,
        pickTime: false,
        autoclose: true,
        startView: 'decade',
        defaultViewDate: { year: current_year},
        format: "yyyy-mm-dd"
    });

    //Input mask on postalCode
    $("#pay-and-submit-claim-form .pay-and-submit-claim-form-group-postalCode #postalCode").inputmask("A9A 9A9");

    //Input mask on phoneNumber
    $("#pay-and-submit-claim-form .pay-and-submit-claim-form-group-phoneNumber  #phoneNumber").inputmask("(999) 999-9999 [x9999]");

    //Input mask on clientId
    $(".pay-and-submit-card-form-group-clientId #clientId").inputmask("AAAAAAAAAA999AA");

    $("#pharmacyReceipt").on('change', function(e){
        var labelVal = "";
        $('#btn-submit').removeAttr('disabled');
        if( this.files && this.files.length > 1 ){
            fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
        } else if (this.files && this.files.length == 0){
            fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
        } else if( e.target.value ){
            fileName = e.target.value.split( '\\' ).pop();
        }

        if( fileName ){
            $('#uploadCount').html( fileName );
        }
        else{
            $('#uploadCount').html( labelVal );
        }
        var fileSizing = 0;
        for(var i = 0; i < this.files.length; i++){
            if(this.files[i].size > maxFileSize || fileSizing > maxFileSize){
                fileSizing += this.files[i].size;
                $('#btn-submit').attr('disabled', 'disabled');
                if($url.indexOf('/en/') >= 0) {
                    alert("Pharmacy Receipt file is above the allowed limit of 7 MB");
                } else {
                    alert("Fichier de reçu pharmaceutique dépasse la limite autorisée de 7 Mo.");
                }
                break;
            }
        }

    });

});



/**
 * function clear_brand_details()
 **/
 function clear_brand_details(){

    $("#pay-and-submit-claim-form #brandName").val("");
 }

/**
 * function fill_brand_details()
 **/
 function fill_brand_details(){

    $("#pay-and-submit-claim-form #brandName").val("");

    var din = $("#pay-and-submit-claim-form #din").val();

    if(din.length > 0){

        //Show and indication we are looking up med info
        $("#spinner-holder").show();

        $.ajax({
            url: APP_URL+"/din/"+din,
            type: "GET",
                success: function(data){
                    console.log(data);
                    $("#pay-and-submit-claim-form #brandName").val(data.brandName);
                    $("#spinner-holder").hide();
                },
                error: function(x,e) {
                    console.log(data);

                }
        });
    }
 }